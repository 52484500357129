<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs4 sm4 md4 lg4 class="text-left">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs4 sm4 md4 lg4 class="text-right">
        <v-btn
          class="mr-1 mb-1"
          small
          outlined
          @click="editStore($route.params.id)"
          :color="$store.state.primaryColor"
          ><span class="caption">{{ $t("edit") }}</span>
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn
          small
          outlined
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
          ><span class="caption">{{ $t("delete") }}</span>
          <v-icon small>delete</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card dark>
        <v-card-title class="justify-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete") }}
            <span class="font-weight-medium"> {{ details.businessName }}</span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            @click="deleteStore"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      shaped
      top
      rounded="pill"
      v-model="snackbar"
      :timeout="timeout"
      color="blue-grey"
    >
      {{ $t("deleted") }} !
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar top v-model="snackErr" :timeout="timeout">
      {{ $t("an error ocurred") }} : <span>{{ errorMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackErr = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <v-row v-else>
      <v-col cols="12" md="3">
        <v-card class="mx-auto" max-width="344">
          <v-card-title class="body-2">
            {{ details.abbreviation }} {{ $t("business-logo") }}Logo
          </v-card-title>
          <v-img :src="details.logo" height="150px"></v-img>
        </v-card>
        <v-card class="mx-auto mt-2" max-width="344">
          <v-card-title class="body-2">
            {{ details.abbreviation }} {{ $t("business-banner") }}
          </v-card-title>
          <v-img :src="details.banner" height="150px"></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <v-card>
          <v-container class="caption">
            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("business name") }}:
                </span>
                <span>{{ details.businessName }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("type of business") }}:
                </span>
                <span class="">{{ details.businessType }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("verification status") }}:
                </span>
                <span class="">{{ setStatus(details.verified) }} </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("email") }}: </span>
                <span class="">{{ details.email }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("telephone") }}: </span>
                <span class="">{{ details.phone }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("PO BOX") }}: </span>
                <span>{{ details.address.pobox }}</span>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("website") }}: </span>
                <span class="">{{ details.website }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("registration number") }}:
                </span>
                <span class="">{{ details.regNo }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("permit number") }}:
                </span>
                <span class="">{{ details.permitNo }} </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("pin") }}: </span>
                <span class="">{{ details.pin }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("bank name") }}: </span>
                <span class="">{{ details.bank.bankName }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("bank account number") }}:
                </span>
                <span class="">{{ details.bank.accountNo }} </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("glopay") }}: </span>
                <span class="">{{ details.glopay }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("mpesa paybill number") }}:
                </span>
                <span class="">{{ details.mpesa.paybillNo }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("mpesa account number") }}:
                </span>
                <span class="">{{ details.bank.accountNo }} </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("mpesa till number") }}:
                </span>
                <span class="">{{ details.mpesa.tillNo }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("country") }}: </span>
                <span class="">{{ details.address.country }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("county-province") }}:
                </span>
                <span class="">{{ details.address.county }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("city-town") }}: </span>
                <span class="">{{ details.address.city }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("district-territory") }}:
                </span>
                <span class="">{{ details.address.territory }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("street") }}: </span>
                <span>{{ details.address.street }} </span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("estate") }}: </span>
                <span>{{ details.address.estate }}</span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium">{{ $t("building") }}: </span>
                <span>{{ details.address.building }}</span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-medium"
                  >{{ $t("house-suite-number") }}:
                </span>
                <span>{{ details.address.no }} </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";
// import { add } from 'date-fns';

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    details: "",
    snackbar: false,
    pleaseWait: true,
    timeout: 2000,
    delDialog: false,
    snackErr: false,
    errorMessage: "",
  }),

  created() {
    this.getStoreDetails();
  },

  methods: {
    getStoreDetails() {
      db.collection("businesses")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.details = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          this.errorMessage = error;
          this.snackErr = true;
        });
    },

    setStatus(val) {
      if (val) {
        return "Verified";
      } else {
        return "Pending";
      }
    },

    editStore(busID) {
      this.$router.push({ name: "EditStore", params: { id: busID } });
    },

    deleteStore() {
      db.collection("businesses")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.snackbar = true;
          setTimeout(function () {
            window.history.back();
          }, 2000);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
